<!-- 账户充值管理 -->
<template>
  <div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1300 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="rechargeList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button
              v-show="userinfo.roles[0].id == 1"
              type="link"
              size="small"
              @click="doCheckRecharge(record)"
              :disabled="record.status == 1"
            >
              <CheckOutlined />审核
            </a-button>
            <a-divider
              v-show="userinfo.roles[0].id == 1"
              type="vertical"
              style="border-color: #7cb305"
              dashed
            />
            <a-button
              v-show="userinfo.roles[0].id == 1"
              type="link"
              size="small"
              @click="doCheckRecharge(record)"
              :disabled="record.status == 0"
            >
              <UndoOutlined />反审
            </a-button>
            <a-divider
              v-show="userinfo.roles[0].id == 1"
              type="vertical"
              style="border-color: #7cb305"
              dashed
            />
            <a-button
              type="link"
              size="small"
              @click="doDeleteRecharge(record)"
              :disabled="record.status == 1"
            >
              <DeleteOutlined />删除
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ExclamationCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import {
  getRechargeInfoListPage,
  checkRechargeInfo,
  deleteRechargeInfo,
} from "@/api/account/recharge";
import { message, Modal } from "ant-design-vue";
import moment from "moment";
export default defineComponent({
  name: "Recharge",
  components: {
    DeleteOutlined,
    CheckOutlined,
    UndoOutlined,
  },
  setup() {
    const state = reactive({
      loading: false,
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      rechargeInfo: {
        rechargeId: null,
        rechargeName: "",
      },
      editData: {
        id: null,
        rechargeName: "",
        rechargeDesc: "",
        rechargeSort: null,
      },
      searchParam: {
        pageIndex: 1,
        pageSize: 10,
        rid: JSON.parse(sessionStorage.getItem("userinfo")).roles[0].id,
      },
      rechargeList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "账户名称",
        dataIndex: "accountInfo.uname",
        key: "uname",
        align: "center",
        width: 200,
      },
      {
        title: "充值金额",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        width: 200,
      },
      {
        title: "申请状态",
        dataIndex: "rechargeStatusInfo.item",
        ellipsis: true,
        key: "rechargeStatus",
        align: "center",
        width: 200,
      },
      {
        title: "审核时间",
        dataIndex: "checkDate",
        key: "checkDate",
        align: "center",
        width: 200,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:ss:mm") : "";
        },
      },
      {
        title: "申请时间",
        dataIndex: "createDate",
        align: "center",
        key: "createDate",
        width: 200,
        sorter: (a, b) => (a.createDate < b.createDate ? 1 : -1),
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:ss:mm") : "";
        },
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 300,
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      initRechargeList(state.searchParam);
    });
    const initRechargeList = (searchParam) => {
      state.loading = true;
      getRechargeInfoListPage(searchParam).then((resp) => {
        if (resp) {
          state.rechargeList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      (state.searchParam.pageIndex = 1),
        (state.searchParam.pageSize = 10),
        (state.searchParam.rid = state.userinfo.roles[0].id);
      initRechargeList(state.searchParam);
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      state.searchParam.rid = state.userinfo.roles[0].id;
      initRechargeList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      state.searchParam.rid = state.userinfo.roles[0].id;
      initRechargeList(state.searchParam);
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const handleChapterModalVisible = (flag) => {
      state.chapterModalVisible = flag;
    };
    const doDeleteRecharge = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将永久删除此次充值记录，是否继续？"
        ),
        onOk() {
          deleteRechargeInfo({ id: record.id, logic: true }).then((resp) => {
            if (resp) {
              initRechargeList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除此次充值记录操作！！！");
        },
      });
    };

    const doCheckRecharge = (record) => {
      console.log(record.rechargeStatusInfo.value == 1 ? 0 : 1);
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将" +
            (record.status == 0 ? "审核" : "反审") +
            "此次充值记录，是否继续？"
        ),
        onOk() {
          checkRechargeInfo({
            id: record.id,
            val: record.rechargeStatusInfo.value == 1 ? 0 : 1,
          }).then((resp) => {
            if (resp) {
              initRechargeList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info(
            "已取消删除此次充值记录" +
              (record.status == 0 ? "审核" : "反审") +
              "操作！！！"
          );
        },
      });
    };

    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initRechargeList,
      onSearch,
      onChangePage,
      onShowSizeChange,
      handleModalVisible,
      handleChapterModalVisible,
      doDeleteRecharge,
      doCheckRecharge,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>